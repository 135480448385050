import * as React from 'react';
import { ParsedQuery, parse } from 'query-string';
import { graphql } from 'gatsby';

import {
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';

import { IndexMetadata } from '../interfaces';

import Metadata from '../metadata';

interface Props extends IndexMetadata {}

interface State {
  isMasked: boolean;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  company: string;
  telephone: string;
  email: string;
  password: string;
  postcode: string;
  prefecture: string;
  address: string;
  inquery: string;
  agreePrivacyPolicy: boolean;
}

const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

const initialState = {
  isMasked: false,
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  company: '',
  telephone: '',
  email: '',
  password: '',
  postcode: '',
  prefecture: '',
  address: '',
  inquery: '',
  agreePrivacyPolicy: false,
};

export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount(): void {
    const withdesk: HTMLScriptElement = document.createElement('script');

    const parsedQuery: ParsedQuery<string> = parse(window.location.search);

    const containerId: string = ((query: ParsedQuery<string>): string => {
      if (query.c) {
        if (typeof query.c === 'string') {
          return query.c;
        }

        console.warn("Query string 'c' should be a string.");

        return '83cc2d49-a82f-46cf-b3c5-a662a8fa78c9';
      }

      return '83cc2d49-a82f-46cf-b3c5-a662a8fa78c9';
    })(parsedQuery);

    const detectEnvironmentId = (query: ParsedQuery<string>): string | undefined => {
      if (query.eid) {
        if (typeof query.eid === 'string') {
          const environmentIds: RegExpMatchArray | null = query.eid.match(/[0-9a-f]{8}/);

          if (!environmentIds) {
            console.warn("Query string 'eid' should be a 8 digit characters");
            return undefined;
          }

          return environmentIds[0];
        }
      }

      console.warn("Query string 'eid' should be assigned");
      return undefined;
    };

    const domain: string = ((query: ParsedQuery<string>): string => {
      if (query.e) {
        if (typeof query.e === 'string') {
          switch (query.e) {
            case 'production': {
              return 'js.withdesk.com';
            }

            case 'development': {
              const environmentId: string | undefined = detectEnvironmentId(query);

              if (typeof environmentId === 'string') {
                return `js-dev-${ environmentId }.withdesk.com`;
              }

              return 'js-dev-.withdesk.com';
            }

            case 'staging': {
              const environmentId: string | undefined = detectEnvironmentId(query);

              if (typeof environmentId === 'string') {
                return `js-stg-${ environmentId }.withdesk.com`;
              }

              return 'js-stg-63e624d4.withdesk.com';
            }

            default: {
              console.warn("Query string 'e' should be 'production', 'staging' or 'development'.");
              return 'js.withdesk.com';
            }
          }
        }

        console.warn("Query string 'e' should be a string.");
        return 'js.withdesk.com';
      }

      return 'js.withdesk.com';
    })(parsedQuery);

    withdesk.setAttribute('async', '');
    withdesk.setAttribute('src', `https://${ domain }/${ containerId }`);

    document.head.appendChild(withdesk);
  }

  switchMasking(e: React.MouseEvent<HTMLButtonElement>): void {
    this.setState({
      isMasked: !this.state.isMasked,
    });
  }

  changeForm(e: React.ChangeEvent<HTMLInputElement>): void {
    switch (e.target.type) {
      case 'checkbox': {
        this.setState({
          [e.target.name]: e.target.checked as any,
        } as Pick<State, keyof State>);

        break;
      }

      default: {
        this.setState({
          [e.target.name]: e.target.value as any,
        } as Pick<State, keyof State>);

        break;
      }
    }
  }

  resetForm(): void {
    this.setState(initialState);
  }

  submitForm(): void {
    this.resetForm();

    window.alert('送信しました');
  }

  render(): JSX.Element {
    return (
      <Grid>
        <Metadata { ...this.props.data.site.siteMetadata.index } />
        <Grid
          container={ true }
          direction="column"
          style={ {
            height: '100vh',
          } }
          wrap="nowrap"
        >
          <Grid
            component="header"
            style={ {
              height: '64px',
            } }
          >
            <Grid
              component="nav"
              container={ true }
              justify="center"
              style={ {
                height: '64px',
                padding: '16px 32px',
              } }
            >
              <img
                alt="logo"
                className={ this.state.isMasked ? 'no-cobrowse' : undefined }
                src="/assets/images/logo.png"
                style={ {
                  height: '32px',
                } }
              />
            </Grid>
          </Grid>
          <Grid
            component="main"
            container={ true }
            justify="center"
            style={ {
              background: '#fafafa',
              flexGrow: 1,
              padding: '24px 8px',
            } }
          >
            <Paper
              elevation={ 0 }
              style={ {
                maxWidth: '640px',
                padding: '24px',
                width: '100%',
              } }
            >
              <Grid
                container={ true }
                style={ {
                  padding: '16px 0px',
                } }
                wrap="nowrap"
              >
                <Grid
                  container={ true }
                >
                  <Typography
                    component="h2"
                    variant="h6"
                  >
                    お問い合わせフォーム
                  </Typography>
                </Grid>
                <Tooltip
                  title={ this.state.isMasked ? 'マスキングしない' : 'マスキングする' }
                >
                  <IconButton
                    onClick={ this.switchMasking.bind(this) }
                    size="small"
                  >
                    { ((): JSX.Element => {
                      if (this.state.isMasked) {
                        return (
                          <VisibilityOff />
                        );
                      }

                      return (
                        <Visibility />
                      );
                    })() }
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="lastName"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? '姓（マスキング）' : '姓' }
                  margin="normal"
                  name="lastName"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="山田"
                  type="text"
                  value={ this.state.lastName }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="firstName"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? '名（マスキング）' : '名' }
                  margin="normal"
                  name="firstName"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="太郎"
                  type="text"
                  value={ this.state.firstName }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="lastNameKana"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? 'セイ（マスキング）' : 'セイ' }
                  margin="normal"
                  name="lastNameKana"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="ヤマダ"
                  type="text"
                  value={ this.state.lastNameKana }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="firstNameKana"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? 'メイ（マスキング）' : 'メイ' }
                  margin="normal"
                  name="firstNameKana"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="タロウ"
                  type="text"
                  value={ this.state.firstNameKana }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="company"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? '会社名（マスキング）' : '会社名' }
                  margin="normal"
                  name="company"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="ウィズデスク株式会社"
                  type="text"
                  value={ this.state.company }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="telephone"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? '電話番号（マスキング）' : '電話番号' }
                  margin="normal"
                  name="telephone"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="03-1234-5678"
                  type="tel"
                  value={ this.state.telephone }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="email"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? 'メールアドレス（マスキング）' : 'メールアドレス' }
                  margin="normal"
                  name="email"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="taro-yamada@withdesk.com"
                  type="email"
                  value={ this.state.email }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="password"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? 'パスワード（マスキング）' : 'パスワード' }
                  margin="normal"
                  name="password"
                  onChange={ this.changeForm.bind(this) }
                  type="password"
                  value={ this.state.password }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="postcode"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? '郵便番号（マスキング）' : '郵便番号' }
                  margin="normal"
                  name="postcode"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="141-0001"
                  type="text"
                  value={ this.state.postcode }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  fullWidth={ true }
                  id="prefecture"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? '都道府県（マスキング）' : '都道府県' }
                  margin="normal"
                  name="prefecture"
                  onChange={ this.changeForm.bind(this) }
                  select={ true }
                  value={ this.state.prefecture }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                  SelectProps={ {
                    native: true,
                  } }
                >
                  <option
                    disabled={ true }
                    value=""
                  >
                    （選択してください）
                  </option>
                  { prefectures.map((prefecture: string, index: number): JSX.Element => (
                    <option
                      key={ index }
                      value={ prefecture }
                    >
                      { prefecture }
                    </option>
                  )) }
                </TextField>
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="address"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? '住所（マスキング）' : '住所' }
                  margin="normal"
                  name="address"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="品川区北品川五丁目５番１５号"
                  type="text"
                  value={ this.state.address }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
              </Grid>
              <Grid
                container={ true }
                wrap="nowrap"
              >
                <TextField
                  autoComplete="off"
                  fullWidth={ true }
                  id="inquery"
                  inputProps={ {
                    className: this.state.isMasked ? 'no-cobrowse' : undefined,
                  } }
                  label={ this.state.isMasked ? 'お問い合わせ内容（マスキング）' : 'お問い合わせ内容' }
                  margin="normal"
                  multiline={ true }
                  name="inquery"
                  onChange={ this.changeForm.bind(this) }
                  placeholder="デモが見たい"
                  type="text"
                  value={ this.state.inquery }
                  variant="outlined"
                  InputLabelProps={ {
                    shrink: true,
                  } }
                />
              </Grid>
              <Grid
                container={ true }
                style={ {
                  padding: '16px',
                } }
              >
                <Typography
                  className={ this.state.isMasked ? 'no-cobrowse' : undefined }
                  gutterBottom={ true }
                  variant="body2"
                >
                  プライバシーポリシーに同意いただいたうえで送信してください。
                </Typography>
                <label
                  className={ this.state.isMasked ? 'no-cobrowse' : undefined }
                  htmlFor="agreePrivacyPolicy"
                >
                  <input
                    checked={ this.state.agreePrivacyPolicy }
                    className={ this.state.isMasked ? 'no-cobrowse' : undefined }
                    id="agreePrivacyPolicy"
                    name="agreePrivacyPolicy"
                    onChange={ this.changeForm.bind(this) }
                    type="checkbox"
                  />
                  プライバシーポリシーに同意する
                </label>
              </Grid>
              <Grid
                container={ true }
                justify="flex-end"
                style={ {
                  marginTop: '16px',
                } }
              >
                <Button
                  onClick={ this.resetForm.bind(this) }
                  variant="contained"
                >
                  リセット
                </Button>
                <Button
                  color="primary"
                  onClick={ this.submitForm.bind(this) }
                  style={ {
                    marginLeft: '12px',
                  } }
                  variant="contained"
                >
                  送信
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export const query: void = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        index {
          description,
          keywords,
          og {
            description,
            image,
            site_name,
            title,
            type,
            url,
          },
          robots,
          title,
          twitter {
            card,
            site,
          },
          url,
        },
      },
    },
  },
`;
