import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Metadata } from '../interfaces';

interface Props extends Metadata {}

interface State {}

export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render(): JSX.Element {
    return (
      <Helmet
        htmlAttributes={ {
          lang: 'ja',
        } }
      >
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1,viewport-fit=cover" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        {
          this.props.title &&
            <title>{ this.props.title }</title>
        }
        {
          this.props.url &&
            <link rel="canonical" href={ this.props.url } />
        }
        {
          this.props.robots &&
            <meta name="robots" content={ this.props.robots } />
        }
        {
          this.props.description &&
            <meta name="description" content={ this.props.description } />
        }
        {
          this.props.keywords &&
            <meta name="keywords" content={ this.props.keywords } />
        }
        {
          this.props.og.type &&
            <meta property="og:type" content={ this.props.og.type } />
        }
        {
          this.props.og.url &&
            <meta property="og:url" content={ this.props.og.url } />
        }
        {
          this.props.og.title &&
            <meta property="og:title" content={ this.props.og.title } />
        }
        {
          this.props.og.description &&
            <meta property="og:description" content={ this.props.og.description } />
        }
        {
          this.props.og.image &&
            <meta property="og:image" content={ this.props.og.image } />
        }
        {
          this.props.og.site_name &&
            <meta property="og:site_name" content={ this.props.og.site_name } />
        }
        {
          this.props.twitter.card &&
            <meta name="twitter:card" content={ this.props.twitter.card } />
        }
        {
          this.props.twitter.site &&
            <meta name="twitter:site" content={ this.props.twitter.site } />
        }
      </Helmet>
    );
  }
}
